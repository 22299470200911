<template>
  <layout-empty>
    <v-row no-gutters :class="{ 'fill-height': $vuetify.breakpoint.smAndDown }">
      <v-spacer />
      <v-col cols="12" lg="5">
        <base-card :title="$t('users.manageMechanics')">
          <v-form
            :disabled="loading"
            ref="form"
            v-model="valid"
            @submit.enter.prevent="validate"
          >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      :label="$t('common.emailRequired')"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-title>
                      {{ $t("users.authorizedMechanics") }}
                    </v-card-title>
                    <v-data-table
                      dense
                      :headers="headers"
                      :items="getMechanics"
                      hide-default-footer
                      fixed-header
                      :items-per-page="-1"
                      :height="5 * 48"
                      :mobile-breakpoint="0"
                      :loading="loading"
                    >
                      <template #item.actions="{ item }">
                        <base-button icon @click="() => revoke(item)">
                          <v-icon>mdi-delete</v-icon>
                        </base-button>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <button-holder>
              <template #btn1>
                <v-btn text @click="$helpers.pushRoute('/logout')">
                  {{ $t("common.logout") }}
                </v-btn>
              </template>
              <template #btn2>
                <v-btn
                  :disabled="!valid"
                  :loading="loading"
                  color="primary"
                  @click="validate"
                >
                  {{ $t("users.invite") }}
                </v-btn>
              </template>
            </button-holder>
          </v-form>
        </base-card>
      </v-col>
      <v-spacer />
      <v-footer app fixed height="48" v-if="isPortrait && !loading">
        <portal-target name="btn1" />
        <v-spacer />
        <portal-target name="btn2" />
      </v-footer>
    </v-row>
  </layout-empty>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";

export default {
  name: "DealerPage",
  components: { ButtonHolder },
  data() {
    return {
      valid: false,
      loading: false,
      email: "",
      emailRules: [
        (v) =>
          !!v ||
          this.$t("error.isRequired", { field: this.$t("common.email") }),
      ],
      headers: [
        { text: this.$t("common.email"), value: "email", sortable: false },
        { text: this.$t("common.actions"), value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("layout", ["isPortrait"]),
    ...mapGetters("dealer", ["getMechanics"]),
  },
  methods: {
    async validate() {
      this.loading = true;
      this.$refs.form.validate();
      await this.$store.dispatch("dealer/addMechanic", this.email);
      this.loading = false;
    },
    async revoke(mechanic) {
      this.loading = true;
      await this.$store.dispatch("dealer/deleteMechanic", mechanic);
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
